@font-face {
    font-family: 'CruyffSans';
    src: url('./assets/CruyffSans-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'CruyffSans';
    src: url('./assets/CruyffSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'CruyffSans';
    src: url('./assets/CruyffSans-Medium.ttf') format('truetype');
    font-weight: 500;
font-style: normal;
}
